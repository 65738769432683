import { graphql } from 'gatsby';
import queryString from 'query-string';
import React from 'react';

import { CancellationIssues } from '../components';
import SEO from '../components/seo';

export const query = graphql`
  query {
    Check: file(relativePath: { eq: "check.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const CancellationSurvey = (props) => {
  let param = queryString.parse(props.location.search);

  return (
    <>
      <SEO title="Pesquisa de Cancelamento" />
      <CancellationIssues idPerson={param.z} data={props.data} />
    </>
  );
};

export default CancellationSurvey;
